import React, { useState } from 'react';

import MeshContainer from '../../../thunderbolt-core-components/MeshContainer/viewer/MeshContainer';
import { IHeaderContainerProps } from '../HeaderContainer.types';
import { useScrollPosition } from '../../../providers/useScrollPosition';

const ScrollDirection = {
  up: 'up',
  down: 'down',
} as const;
const MOBILEVIEW = 'mobile';

const getAnimationStartThresholdForMobile = () => {
  // start animation immediately, don't wait for certain scroll position
  return 1;
};

const getAnimationStartThresholdForDesktop = (animation: string) => {
  switch (animation) {
    case 'HeaderFadeOut':
      return 200;
    case 'HeaderHideToTop':
      return 400;
    default:
      return null;
  }
};

const HeaderContainer: React.FC<IHeaderContainerProps> = props => {
  const {
    id,
    skin: HeaderContainerClass,
    children,
    animations,
    meshProps,
  } = props;

  const sdkEventHandlers = {
    onMouseEnter: props.onMouseEnter,
    onMouseLeave: props.onMouseLeave,
    onClick: props.onClick,
    onDoubleClick: props.onDblClick,
  };

  const [animationProp, setAnimationProp] = useState<string>('');
  let direction: typeof ScrollDirection[keyof typeof ScrollDirection] =
    ScrollDirection.down;
  let directionFlipPosition = 0;

  useScrollPosition(
    ({ currPos, prevPos }) => {
      // multiply by -1 if not zero (-0 is confusing during debug)
      const currentPosition = currPos.y && currPos.y * -1;
      const prevPosition = prevPos.y && prevPos.y * -1;

      animations!.forEach(animationObject => {
        // until TB will fix the type
        // @ts-ignore
        animationObject.params.animations.forEach(animation => {
          const animationStartThreshold =
            animationObject.viewMode?.toLowerCase() === MOBILEVIEW
              ? getAnimationStartThresholdForMobile()
              : getAnimationStartThresholdForDesktop(animation.name);
          if (animationStartThreshold) {
            // catch scroll direction change and record scroll flip position
            // directionFlipPosition defaults to 0 (top of the page), but if we flip scroll direction,
            // this will be the new directionFlipPosition, from which we calculate when to start animation
            if (
              direction === ScrollDirection.down &&
              currentPosition < prevPosition
            ) {
              directionFlipPosition = prevPosition;
              direction = ScrollDirection.up;
            } else if (
              direction === ScrollDirection.up &&
              currentPosition > prevPosition &&
              currentPosition >= 0 &&
              prevPosition >= 0 // ignore direction flip outside scrollport, otherwise with rubber scroll on Safari header disappears at the top of the page
            ) {
              directionFlipPosition = prevPosition;
              direction = ScrollDirection.down;
            }

            if (animation.name === animationProp) {
              if (
                // we have scrolled far enough from the position where scroll direction was flipped or we are at the top of the page
                (direction === ScrollDirection.up &&
                  currentPosition + animationStartThreshold <
                    directionFlipPosition) ||
                currPos.y === 0 // make sure header is always visible at the top of the page. Due to rubber scroll on Safari it may disappear after overscroll
              ) {
                // show header by running amimation
                setAnimationProp(animation.name + 'Reverse');
              }
            } else if (
              // we have scrolled far enough from the position where scroll direction was flipped
              direction === ScrollDirection.down &&
              currentPosition - directionFlipPosition >= animationStartThreshold
            ) {
              // hide header by running amimation
              setAnimationProp(animation.name);
            }
          }
        });
      });
    },
    [animationProp],
    { disabled: !animations || !animations.length },
  );

  return (
    <HeaderContainerClass
      wrapperProps={{ id, eventHandlers: sdkEventHandlers }}
      animation={animationProp}
    >
      <MeshContainer id={id} {...meshProps} children={children} />
    </HeaderContainerClass>
  );
};

export default HeaderContainer;
